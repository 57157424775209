<template>
    <div>
        <b-card no-body class="mb-4">
            <div class="d-flex justify-content-between mb-3">
                <h4 class="mb-3">Financial Obligations</h4>
                <b-button variant="primary" @click="addObligation">Add Obligation</b-button>
            </div>

            <div style="gap: 8px; display: grid; grid-template-columns: repeat(5,1fr);">
                <b-form-group label="Bank" label-for="bank" :state="bankState"
                    invalid-feedback="This field is required">
                    <b-form-select v-model="bank" id="bank" :state="bankState" @blur="validateBank">
                        <option value="" disabled>Select a bank</option>
                        <option v-for="bank in banks" :key="bank.id" :value="bank">{{ bank.name }}</option>
                    </b-form-select>
                </b-form-group>

                <!-- Campo: Tipo de obligación -->
                <b-form-group label="Type" label-for="obligationtype" :state="obligationTypeState"
                    invalid-feedback="This field is required">
                    <b-form-select v-model="obligationtype" id="obligationtype" :state="obligationTypeState"
                        @blur="validateObligationType">
                        <option value="" disabled>Select obligation type</option>
                        <option value="Loan">Loan</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="Mortgage">Mortgage</option>
                        <option value="Other">Other</option>
                    </b-form-select>
                </b-form-group>

                <!-- Campo: Fecha -->
                <b-form-group label="Start Date" label-for="obligationdate" :state="startDateState">
                    <b-form-input type="date" v-model="obligationdate" id="obligationdate" :state="startDateState"
                        placeholder="Enter date"></b-form-input>
                </b-form-group>

                <b-form-group label="Amount" label-for="amount" :state="amountState"
                    invalid-feedback="This field is required and must be greater than 0" class="px-2">
                    <b-form-input v-model="amount" id="amount" type="number" :state="amountState" @blur="validateAmount"
                        placeholder="Enter amount"></b-form-input>
                </b-form-group>


                <!-- Monthly Payment Field -->
                <b-form-group label="Monthly Payment" label-for="monthlypayment" :state="monthlyPaymentState"
                    invalid-feedback="This field is required and must be greater than 0">
                    <b-form-input v-model="monthlypayment" id="monthlypayment" type="number"
                        :state="monthlyPaymentState" @blur="validateMonthlyPayment"
                        placeholder="Enter monthly payment"></b-form-input>
                </b-form-group>
            </div>

            <!-- Obligations List Table -->
            <b-table striped hover small :items="obligations" class="mt-3" :fields="fields">
                <!-- Existing columns -->
                <template #cell(bank)="data">
                    <b>{{ data.item.bankname }}</b>
                </template>
                <template #cell(obligationtype)="data">
                    <b>{{ data.item.obligationtype }}</b>
                </template>
                <template #cell(obligationdate)="data">
                    {{ data.item.obligationdate }}
                </template>
                <template #cell(amount)="data">
                    AWG {{ data.item.amount }}
                </template>
                <template #cell(monthlypayment)="data">
                    AWG {{ data.item.monthlypayment }}
                </template>

                <!-- Delete Button -->
                <template #cell(action)="data">
                    <b-button variant="outline-danger" size="sm" @click="deleteObligation(data.index)">
                        <feather type="trash" class="feather-sm"></feather>

                    </b-button>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Obligations",
    props: {
        readOnly: Boolean,
    },
    data() {
        return {
            // Fields and states
            bank: "",
            obligationtype: "",
            obligationdate: "",
            amount: null,
            monthlypayment: null,

            bankState: null,
            obligationTypeState: null,
            startDateState: null,
            amountState: null,
            monthlyPaymentState: null,

            obligations: [],
            banks: [],
            fields: [
                { key: 'bankid', label: 'Bank Id' },
                { key: 'bankname', label: 'Bank Name' },
                { key: 'obligationtype', label: 'Obligation Type' },
                { key: 'obligationdate', label: 'Date' },
                { key: 'amount', label: 'Amount' },
                { key: 'monthlypayment', label: "Monthly Payment" },
                { key: 'action', label: "Action" }
            ]

        };
    },
    computed: {
        ...mapGetters({
            loan: 'applications/getLoan'
        }),
    },
    methods: {
        async getBanks() {
            let data = { skip: 1, take: 50 };
            const response = await this.$store.dispatch("banks/get_allBanks", data);
            this.banks = response?.dataResult ?? [];
        },
        validateBank() { this.bankState = this.bank !== ""; },
        validateObligationType() { this.obligationTypeState = this.obligationtype !== ""; },
        // validateStartDate() { this.startDateState = this.obligationdate !== ""; },
        validateAmount() { this.amountState = this.amount > 0; },
        validateMonthlyPayment() { this.monthlyPaymentState = this.monthlypayment > 0; },

        addObligation() {
            this.validateBank();
            this.validateObligationType();
            // this.validateStartDate();
            this.validateAmount();
            this.validateMonthlyPayment();

            if (this.bankState && this.obligationTypeState && this.amountState && this.monthlyPaymentState) {
                this.obligations.push({
                    bankid: this.bank.id,
                    bankname: this.bank.name,
                    obligationtype: this.obligationtype,
                    obligationdate: this.obligationdate,
                    amount: this.amount,
                    monthlypayment: this.monthlypayment,
                });
                this.saveData();
                this.resetForm();
            }
        },
        resetForm() {
            this.bank = null;
            this.obligationtype = "";
            this.obligationdate = "";
            this.amount = null;
            this.monthlypayment = null;
            this.bankState = null;
            this.obligationTypeState = null;
            this.startDateState = null;
            this.amountState = null;
            this.monthlyPaymentState = null;
        },
        deleteObligation(index) {
            this.obligations.splice(index, 1);
            this.saveData();
        },
        async saveData() {
            await this.$store.dispatch('applications/update_financialObligations', {
                id: this.$route.query.loanApplicationId,
                body: this.obligations
            });
            await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
        }
    },
    watch: {
        loan(loanApp) {
            if (JSON.parse(loanApp.financialObligations)) {
                this.obligations = JSON.parse(loanApp.financialObligations).map(obligation => {
                    return Object.keys(obligation).reduce((acc, key) => {
                        acc[key.toLowerCase()] = obligation[key];
                        return acc;
                    }, {});
                });

            }
        }
    },
    created() {
        this.getBanks();
    }
};
</script>
