<template>
	<b-row>
		<b-col cols="12">
			<b-card no-body class="mb-4">
				<b-card-body class="p-3">
					<Header />
					<hr class="mb-4" />


					<h3 class="mb-3">OCS Approval Information</h3>
					<b-list-group v-if="process">
						<b-list-group-item class="d-flex justify-content-between align-items-center bg-light">
							<div class="d-flex align-items-center">
								<h5 class="mr-2 mb-0">Approve Level 1</h5>
								<b-badge :variant="process.approvalLvl1 ? 'success' : ''">
									{{ process.approvalLvl1 ? "Approved" : "Pending" }}
								</b-badge>
							</div>
							<b-button v-if="hasPermissionLvl1" :variant="process.approvalLvl1 ? 'danger' : 'primary'"
								v-b-modal.modal-update-status
								@click="(serviceSelected = 'ApprovalLvl1'), (status = !process.approvalLvl1), (level = 'Level 1')">{{
									process.approvalLvl1 ? "Revoke" : "Verify" }}</b-button>
						</b-list-group-item>

						<b-list-group-item class="d-flex justify-content-between align-items-center">
							<div class="d-flex align-items-center">
								<h5 class="mr-2 mb-0">Approve Level 1.2</h5>
								<b-badge :variant="process.approvalLvl1_2 ? 'success' : ''">
									{{ process.approvalLvl1_2 ? "Approved" : "Pending" }}
								</b-badge>
							</div>
							<b-button v-if="hasPermissionLvl1p1"
								:variant="process.approvalLvl1_2 ? 'danger' : 'primary'" v-b-modal.modal-update-status
								@click="(serviceSelected = 'ApprovalLvl1_2'), (status = !process.approvalLvl1_2), (level = 'Level 1.2')">{{
									process.approvalLvl1_2 ? "Revoke" : "Verify" }}</b-button>
						</b-list-group-item>

						<b-list-group-item v-if="loanDetail && loanDetail.loanAmount >= 20000"
							class="d-flex justify-content-between align-items-center bg-light">
							<div class="d-flex align-items-center">
								<h5 class="mr-2 mb-0">Approve Level 2</h5>
								<b-badge :variant="process.approvalLvl2 ? 'success' : ''">
									{{ process.approvalLvl2 ? "Approved" : "Pending" }}
								</b-badge>
							</div>
							<b-button v-if="hasPermissionLvl2" :variant="process.approvalLvl2 ? 'danger' : 'primary'"
								v-b-modal.modal-update-status
								@click="(serviceSelected = 'ApprovalLvl2'), (status = !process.approvalLvl2), (level = 'Level 2')">{{
									process.approvalLvl2 ? "Revoke" : "Verify" }}</b-button>
						</b-list-group-item>
					</b-list-group>


					<div class="d-flex justify-content-between align-items-center mb-3 mt-5">
						<h3>Preview Schedule</h3>

						<b-button variant="primary" v-b-modal.modal-preview-schedule>
							<b-spinner small v-if="loadingSchedule"></b-spinner>
							<span v-if="isPreview">Update Schedule</span>
							<span v-else>Create New Schedule</span>
						</b-button>
					</div>

					<b-table style="min-height: 250px; max-height: 300px; overflow-y: scroll;" show-empty responsive
						small class="mb-0 mt-2" head-variant="light" :filter="null" :items="schedules" :fields="fields"
						hover>
						<template #cell(DueDate)="data"> {{ data.item.DueDate && data.item.DueDate.split('T')[0]
							}}</template>
						<template #cell(payment)="data"> AWG {{ data.item.Principal.Amount.Expected }}</template>
						<template #cell(interest)="data"> AWG {{ data.item.Interest.Amount.Expected }}</template>
					</b-table>

					<h3 class="mb-3 mt-5">Post-Control Actions</h3>
					<b-list-group>
						<b-list-group-item class="d-flex justify-content-between align-items-center bg-light">
							<div class="d-flex align-items-center">
								<h5 class="mr-2 mb-0">Create Loan</h5>
							</div>
							<b-button v-if="hasPermissionCreateLoan" variant="primary" @click="createMambuCustomer"
								:disabled="loadingMambu || loan != null || !process.approvalLvl1 || !process.approvalLvl1_2 || !process.approvalLvl2">
								<b-spinner small v-if="loadingMambu"></b-spinner>
								Submit
							</b-button>
						</b-list-group-item>
					</b-list-group>


					<div>

						<div class="d-flex justify-content-between align-items-center mb-3 mt-5">
							<h3 class="">Sign Process</h3>

							<b-button v-if="hasPermissionSign" :variant="'primary'" :disabled="loadingSignProcess"
								@click="createSignProcess">
								<b-spinner small v-if="loadingSignProcess"></b-spinner>
								Start Process
							</b-button>
						</div>

						<b-form-group label="Type of signature selected" v-slot="{ ariaDescribedby }">
							<b-form-radio-group v-model="signSelected" :options="[
								{ text: 'Digital Sign', value: 'digital' },
								{ text: 'Manual Sign', value: 'manual' },
							]" :aria-describedby="ariaDescribedby" name="sign" @change="updateSignature"></b-form-radio-group>
						</b-form-group>

						<b-list-group v-if="signProcess.length > 0">
							<b-list-group-item>
								<div class="d-flex w-100 justify-content-between align-items-center border-bottom mb-2"
									v-for="item in signProcess" :key="item.code">
									<div>
										<h5>{{ item.documentStatusKey }}</h5>
										<p class="text-muted">{{ item.createDate.split('T')[0] }}</p>
									</div>
									<feather type="check-circle" class="feather-md text-success"></feather>
								</div>
							</b-list-group-item>
						</b-list-group>

					</div>

					<div class="d-flex justify-content-end">
						<b-button variant="primary" class="mr-2" v-b-modal.modal-comment-approval>Add a
							comment</b-button>
					</div>

				</b-card-body>
			</b-card>

			<b-modal id="modal-update-status" hide-footer centered title="Confirm" size="sm">
				<b-card-text> Are you sure to want to update status for {{ level }}? </b-card-text>
				<div class="d-flex justify-content-end">
					<b-button variant="secondary" class="mr-2"
						@click="$bvModal.hide('modal-update-status')">No</b-button>
					<b-button variant="primary" @click="updateStatus(serviceSelected, status)">
						<b-spinner small v-if="loading"></b-spinner>
						Yes
					</b-button>
				</div>
			</b-modal>

			<b-modal id="modal-comment-approval" hide-footer centered title="Add a new comment" @close="clearData">
				<b-form @submit.prevent="addComment">
					<b-form-group label="Comment" label-class="fw-medium mb-1" label-for="input-2">
						<b-form-textarea required id="input-2" placeholder="Your comment here" rows="3" no-resize
							v-model="comment"></b-form-textarea>
					</b-form-group>
					<b-button block variant="primary" type="submit" :disabled="loadingAddComment">Save
						Comment</b-button>
				</b-form>
			</b-modal>


			<b-modal id="modal-preview-schedule" hide-footer centered title="Confirm" size="sm">
				<b-card-text v-if="isPreview">Are you sure you want to update the
					schedule?</b-card-text>
				<b-card-text v-else> Are you sure you want to create a new schedule?</b-card-text>
				<div class="d-flex justify-content-end">
					<b-button variant="secondary" class="mr-2"
						@click="$bvModal.hide('modal-preview-schedule')">No</b-button>
					<b-button variant="primary" @click="changeSchedule">
						<b-spinner small v-if="loadingSchedule"></b-spinner>
						Yes
					</b-button>
				</div>
			</b-modal>
		</b-col>
	</b-row>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/customers/Header.vue";
export default {
	name: "Approval",
	components: {
		Header,
	},

	data: () => ({
		loading: false,
		loadingMambu: false,
		loadingState: false,
		loadingFunds: false,
		loadingSchedule: false,
		loadingSignProcess: false,

		status: null,
		serviceSelected: null,
		signSelected: null,
		level: "",
		loan: null,
		loanDetail: null,
		signProcess: [],

		fields: [
			{
				key: "Number",
				sortable: true,
			},
			{
				key: "payment",
				label: "Expected Payment",
				sortable: true,
			},
			{
				key: "interest",
				label: "Expected Interest",
				sortable: true,
			},
			{
				key: "State",
				sortable: true,
			},
			{
				key: "DueDate",
				label: "Date",
				sortable: true,
			},

		],
		schedules: [],
		loadingAddComment: false,
		comment: ''
	}),
	methods: {
		async updateStatus(service, status) {
			if (this.status != null && this.serviceSelected != null) this.loading = true;
			if (service == 'ApprovalLvl1_2' && this.loanDetail.loanAmount < 20000) {
				await this.$store.dispatch(`productRequest/update_status${'ApprovalLvl2'}`, {
					id: this.$route.query.loanApplicationId,
					status,
				});
			}

			await this.$store.dispatch(`productRequest/update_status${service}`, {
				id: this.$route.query.loanApplicationId,
				status: status,
			});
			await this.getData();
			this.loading = false;
			this.$bvModal.hide("modal-update-status");
			this.level = "";
			this.serviceSelected = null;
			this.status = null;
		},

		async getData() {
			await this.$store.dispatch("customers/get_customer", this.$route.params.customer);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.query.loanApplicationId);
			await this.getSignProcess()
			await this.getCreatedLoans()
		},

		async getLoan() {
			const response = await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
			this.loanDetail = response.dataResult
			this.signSelected = this.loanDetail.isDigitalSignature ? 'digital' : 'manual'
		},
		async getSignProcess() {
			const response = await this.$store.dispatch("signature/get_signProcess", this.$route.query.loanApplicationId);
			this.signProcess = response
		},
		async getCreatedLoans() {
			const response = await this.$store.dispatch("mambu/get_byLoanApplicationId", this.$route.query.loanApplicationId);
			this.loan = response.data.dataResult

			if (this.loan?.previewSchedulePayload) {
				const data = this.loan.previewSchedulePayload
				const dataParsed = JSON.parse(data)
				this.schedules = dataParsed.Installments
			}
		},
		async createMambuCustomer() {
			this.loadingMambu = true
			if (!this.process.approvalLvl1 || !this.process.approvalLvl1_2 || !this.process.approvalLvl2) return

			if (this.customer.encodedKey == null || this.customer.encodedKey == '') {
				await this.$store.dispatch("mambu/create_mambuCustomer", this.$route.params.customer);
			}
			if (!this.loan) {
				await this.$store.dispatch("mambu/create_mambuLoan", {
					loanApplicationId: this.$route.query.loanApplicationId,
					customerId: this.$route.params.customer,
				});
			}

			await this.getData()
			this.loadingMambu = false
		},

		async changeSchedule() {
			this.loadingSchedule = true

			const response = await this.$store.dispatch("mambu/preview_schedule", {
				loanId: this.loan ? this.loan.loanId : '',
				loanApplicationId: this.$route.query.loanApplicationId,
			});

			if (!this.loan) {
				const data = response?.data?.dataResult?.payload?.installments ?? []
				this.schedules = data.map(el => {
					return {
						DueDate: el.dueDate,
						Interest: {
							Amount: {
								Expected: el.interest.amount.expected
							}
						},
						Principal: {
							Amount: {
								Expected: el.principal.amount.expected
							}
						},
						Number: el.number,
						State: el.state
					}
				})
			}


			await this.getData();
			this.$bvModal.hide("modal-preview-schedule");
			this.loadingSchedule = false
		},
		async createSignProcess() {
			this.loadingSignProcess = true
			await this.$store.dispatch(`signature/create_signProcess`, this.$route.query.loanApplicationId);
			await this.getData();
			this.loadingSignProcess = false

		},
		async updateSignature() {
			await this.$store.dispatch(`applications/update_typeSignature`, {
				id: this.$route.query.loanApplicationId,
				isDigitalSignature: this.signSelected == 'digital'
			});
			await this.getData();

		},
		handleStatus(status) {
			if (status == 'INIT') {
				return {
					icon: 'check-circle',
					color: 'text-sucess'
				}
			}
		},
		async addComment() {
			this.loadingAddComment = true;

			await this.$store.dispatch("comments/add_comment", {
				comment: this.comment,
				customerId: this.customer.additionalDetails.customerId,
				loanApplicationId: this.$route.params.customer,
			});

			await this.getComments();
			this.$bvModal.hide("modal-comment-approval");
			this.comment = "";
			this.loadingAddComment = false;
		},
		clearData() {
			this.comment = "";
			this.commentSelected = null;
		},
		getComments() {
			if (this.$route.path.includes("/loans/applications")) {
				this.$store.dispatch("comments/get_commentsByLoanAppId", this.$route.params.customer);
			}
		},
	},
	computed: {
		...mapGetters({
			process: "productRequest/getProcess",
			customer: "customers/getCustomer",
			authorizations: "auth/getAuthorizations",
		}),
		isValidState() {
			if (this.loan && this.loan.accountState != 'PENDING_APPROVAL') {
				return true
			}
			return false
		},
		isPreview() {
			return this.loan && this.previewSchedulePayload
		},
		hasPermissionTransfer() {
			return this.authorizations.includes('/LoanApplications-Transfer-Of-Founds')
		},
		hasPermissionCreateLoan() {
			return this.authorizations.includes('/LoanApplications-Add')
		},
		hasPermissionSign() {
			return this.authorizations.includes('/LoanApplications-Signature')
		},
		hasPermissionLvl1() {
			return this.authorizations.includes('/LoanApplications-Approval-1')
		},
		hasPermissionLvl1p1() {
			return this.authorizations.includes('/LoanApplications-Approval-1.2')
		},
		hasPermissionLvl2() {
			return this.authorizations.includes('/LoanApplications-Approval-2')
		}
	},
	created() {
		this.getCreatedLoans()
		this.getLoan()
		this.getSignProcess()
	},
};
</script>
