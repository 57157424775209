<template>
	<div>
		<b-card no-body>
			<template v-if="!readOnly">
				<Header />
				<hr class="m-0" />
			</template>
		</b-card>

		<div class="p-4">
			<h4>Customer Employments</h4>
			<EmploymentsTable :id="$route.params.customer" />
		</div>

		<div class="p-4" v-if="loan.coApplicantId">
			<h4>Co Applicant Employments</h4>
			<EmploymentsTable :id="loan.coApplicantId" />
		</div>

		<div v-if="loan.guarantorId" class="p-4">
			<h4>Guarantor Employments</h4>
			<EmploymentsTable :id="loan.guarantorId" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from 'lodash';
import Header from "@/components/customers/Header.vue";
import EmploymentsTable from "./EmploymentsTable.vue";

export default {
	name: "Customer",
	components: {
		Header,
		EmploymentsTable
	},
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		page: {
			title: "employment",
		},
		query: '',
		debouncedSearch: null,
		options: [],
		filter: null,
		fields: [
			{
				key: "id",
				sortable: true,
			},
			{
				key: "employerName",
				label: "Employer Name",
				sortable: true,
			},
			{
				key: "jobDescription",
				label: "Job Description",
				sortable: true,
			},
			{
				key: "fromDate",
				label: "From",
				sortable: true,
			},
			{
				key: "toDate",
				label: "To",
				sortable: true,
			},
			{
				key: "monthlySalary",
				label: "Monthly Salary",
				sortable: false,
			},
			{
				key: "isActive",
				label: "Active",
				sortable: true,
			},
			{
				key: "isVerified",
				label: "Verified",
				sortable: true,
			},
		],

		totalRows: 1,
		currentPage: 1,
		perPage: 10,
		pageOptions: [5, 10, 20, 40, { value: 100, text: "Show a lot" }],

		name: "",
		address: "",
		phone: "",
		jobDescription: "",
		date: "",
		toDate: "",
		salary: "",
		loading: false,
		loadingAddEmployment: false,

		employmentSelected: {},
		isEditing: false,
		customers: [],
		selectedOption: null
	}),
	methods: {
		Delete() {
			this.items = this.items.filter((item) => item.id !== "123");
		},
		clearValues() {
			this.name = "";
			this.address = "";
			this.phone = "";
			this.jobDescription = "";
			this.date = "";
			this.toDate = "";
			this.salary = "";
		},
		formatDate(date) {
			if (!date) return;
			return date.split("T")[0];
		},
		getEmployments() {
			this.loading = true;
			let data = {
				skip: this.currentPage,
				take: this.perPage,
				customerId: this.$route.params.customer,
			};
			this.$store.dispatch("employments/get_employments", data);
			this.loading = false;
		},


		async addEmployer() {
			this.loadingAddEmployment = true;
			let data = {
				employerName: this.name,
				employerAddress: this.address,
				employerTelephoneNumber: this.phone,
				jobDescription: this.jobDescription,
				fromDate: this.date,
				toDate: this.toDate,
				monthlySalary: this.salary,
				customerId: this.$route.params.customer
			};

			if (this.$route.path.includes('/loans/applications')) {
				data.loanApplicationId = this.$route.query.loanApplicationId
			}

			if (this.isEditing) {
				data.id = this.employmentSelected.id;
				data.isActive = true;
				data.isVerified = true;
				data.modifiedBy = 1;
				data.clasificationId = 1;
				await this.$store.dispatch("employments/update_employment", data);
				this.isEditing = false;
			} else {
				await this.$store.dispatch("employments/add_employment", data);
			}

			//clear data
			this.clearValues();

			await this.getEmployments();
			this.$bvModal.hide("modal-register");
			this.loadingAddEmployment = false;
		},
		async viewEmployment(item) {
			this.employmentSelected = await this.$store.dispatch("employments/get_employmentById", item.id);
			this.$bvModal.show("modal-view");
		},
		async editEmployment(item) {
			this.isEditing = true;
			this.employmentSelected = await this.$store.dispatch("employments/get_employmentById", item.id);

			this.name = this.employmentSelected.employerName;
			this.address = this.employmentSelected.employerAddress;
			this.phone = this.employmentSelected.employerTelephoneNumber;
			this.jobDescription = this.employmentSelected.jobDescription;
			this.toDate = this.employmentSelected.toDate.split("T")[0];
			this.fromDate = this.employmentSelected.fromDate.split("T")[0];
			this.salary = this.employmentSelected.monthlySalary;

			this.$bvModal.show("modal-register");
		},
		async updateStatus(status, item) {
			let data = {
				id: item.id,
				employerName: item.employerName,
				employerAddress: item.employerAddress,
				employerTelephoneNumber: item.employerTelephoneNumber,
				jobDescription: item.jobDescription,
				isActive: status,
				modifiedBy: 1,
			};
			await this.$store.dispatch("employments/update_employmentStatus", data);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);
			await this.getEmployments();
		},
		async updateVerified(status, item) {
			let data = {
				id: item.id,
				employerName: item.employerName,
				employerAddress: item.employerAddress,
				employerTelephoneNumber: item.employerTelephoneNumber,
				jobDescription: item.jobDescription,
				isVerified: status,
				modifiedBy: 1,
				loanApplicationId: this.$route.params.customer,
			};
			await this.$store.dispatch("employments/update_employmentVerification", data);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.params.customer);
			await this.getEmployments();
		},
		async getCustomer() {
			const response = await this.$store.dispatch("customers/get_allBusiness", { skip: 1, take: 20, query: this.query });
			this.customers = response?.dataResult ?? []
			this.options = this.customers.map(el => {
				return {
					text: el.firstName,
					value: el.id
				}
			})
		},
		handleOption(item) {
			this.selectedOption = this.customers.find(el => el.id == item.value)
			if (this.selectedOption) {
				this.name = this.selectedOption.firstName || '';
				this.address = this.selectedOption.address1 || '';
				this.phone = this.selectedOption.phone1 || '';
			}
		},
		handleSearchInput(query) {
			this.query = query
			this.debouncedSearch()
		}
	},
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			employments: "employments/getEmployments",
			authorizations: "auth/getAuthorizations",
			loan: 'applications/getLoan'
		}),
		hasPermission() {
			return this.authorizations.includes('/LoanApplications-Job-Verification')
		}
	},
	mounted() {
		if (!this.new) {
			this.getEmployments();
		}

		if (!this.readOnly && this.hasPermission) {
			this.fields.push({
				key: "action",
				label: "Actions",
				sortable: false,
			});
		}
	},
	created() {
		this.debouncedSearch = debounce(this.getCustomer, 300);
	}
};
</script>
