<template>
	<b-overlay :show="loading" rounded="sm">
		<b-card no-body>
			<b-form @submit.prevent="saveData">
				<template v-if="!readOnly">
					<Header />
					<hr class="m-0" />
				</template>

				<!-- ID Info Section -->
				<b-card-body class="mb-5">
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">ID Info</h4>
					<div class="row">
						<div class="col-6">
							<label class="fw-medium mb-1" for="fname4">National ID</label>
							<b-form-input required :readOnly="readOnly" type="text" placeholder="Id Here"
								v-model="dataSelected.identificationType1"></b-form-input>
						</div>
						<div class="col-6">
							<label class="fw-medium mb-1" for="fname4">Passport</label>
							<b-form-input required :readOnly="readOnly" type="text" placeholder="Id Here"
								v-model="dataSelected.identificationType2"></b-form-input>
						</div>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Personalia Section -->

				<b-card-body class="bg-light mb-5">
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">Personal Info</h4>
					<div class="row mb-4">
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Place of Birth</label>
							<b-form-select required :readOnly="readOnly" :options="countries" text-field="name"
								value-field="id" v-model="dataSelected.placeOfBirthId"></b-form-select>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Nationality</label>
							<b-form-select required :readOnly="readOnly" :options="countries" text-field="name"
								value-field="id" v-model="dataSelected.nationalityId"></b-form-select>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Date of birth</label>
							<b-form-input v-model="dataSelected.birthDate" :readOnly="readOnly" required
								type="date"></b-form-input>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label class="fw-medium mb-1" for="comp4">Gender</label>
							<b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
								<b-form-radio-group required :options="[
									{ text: 'Male', value: 'M' },
									{ text: 'Female', value: 'F' },
								]" text-field="text" value-field="value" :aria-describedby="ariaDescribedby" name="input-gender"
									v-model="dataSelected.gender" :readOnly="readOnly"></b-form-radio-group>
							</b-form-group>
						</div>
						<div class="col-3">
							<label class="fw-medium mb-1" for="comp4">Marital Status</label>
							<b-form-group class="mb-0">
								<b-form-select :readOnly="readOnly" required :options="maritalStatus" text-field="name"
									value-field="id" v-model="dataSelected.maritalStatusId"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-3" v-if="dataSelected.maritalStatusId == 1">
							<label class="fw-medium mb-1" for="fname4">Spouses Full Name</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="Spouses full name here"
								v-model="dataSelected.spousesFullName"></b-form-input>
						</div>

						<div class="col-3">
							<label class="fw-medium mb-1" for="comp4">Purpose</label>
							<b-form-group class="mb-0">
								<b-form-select :readOnly="readOnly" required :options="purposes"
									text-field="description" value-field="id" v-model="purposeSelected"></b-form-select>
							</b-form-group>
						</div>
						<div class="col-3">
							<label class="fw-medium mb-1" for="comp4">Dependent Persons</label>
							<b-form-input :readOnly="readOnly" required type="number"
								placeholder="Number of persons here"
								v-model="dataSelected.numberDependentPersons"></b-form-input>

						</div>
					</div>
				</b-card-body>
				<!-- End Section -->


				<b-card-body v-if="loan.loanTypeId == 2">
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">Vehicle Info</h4>
					<div class="row" style="row-gap: 16px;">
						<div class="col-4">
							<label class="fw-medium mb-1">Brand</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Brand Here"
								v-model="dataSelected.brand"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Model</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Model Here"
								v-model="dataSelected.model"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Year</label>
							<b-form-input :readOnly="readOnly" type="number" placeholder="Year Here"
								v-model="dataSelected.year"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">VIN</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="VIN Here"
								v-model="dataSelected.vin"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Condition</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Condition Here"
								v-model="dataSelected.condition"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Color</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Color Here"
								v-model="dataSelected.color"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Mileage</label>
							<b-form-input :readOnly="readOnly" type="number" placeholder="Mileage Here"
								v-model="dataSelected.mileage"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Price</label>
							<b-form-input :readOnly="readOnly" type="number" placeholder="Price Here"
								v-model="dataSelected.price"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Appraisal</label>
							<b-form-input :readOnly="readOnly" type="number" placeholder="Appraisal Here"
								v-model="dataSelected.appraisal"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Seller Name</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Seller Name Here"
								v-model="dataSelected.sellerName"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1">Seller Contact</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Seller Contact Here"
								v-model="dataSelected.sellerContact"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1">Seller Address</label>
							<b-form-input :readOnly="readOnly" type="text" placeholder="Seller Address Here"
								v-model="dataSelected.sellerAddress"></b-form-input>
						</div>

					</div>


				</b-card-body>


				<!-- Bank Info -->
				<b-card-body>
					<h4 class="card-title mb-1 py-2 mb-4 border-bottom">Bank Info</h4>

					<div class="row mb-4">
						<div class="col-6">
							<label class="fw-medium mb-1" for="comp4">Bank</label>
							<b-form-select required :options="banks.filter((e) => e.id != 0)" text-field="name"
								value-field="id" v-model="dataSelected.bankId" :readOnly="readOnly"></b-form-select>
						</div>
						<template v-if="dataSelected.bankId != -1">
							<div class="col-6">
								<label class="fw-medium mb-1" for="comp4">Bank Account Number</label>
								<b-form-input :readOnly="readOnly" required type="text" placeholder="Id Here"
									v-model="dataSelected.bankAccountNumber"></b-form-input>
							</div>
						</template>
					</div>


					<!-- <div class="row">
						<div class="col-4">
							<label class="fw-medium mb-1" for="comp4">Existing Bank Debt</label>
							<b-form-select :readOnly="readOnly" required :options="banks.filter((e) => e.id != -1)"
								text-field="name" value-field="id"
								v-model="dataSelected.existingBankDebt"></b-form-select>
						</div>
						<div class="col-4" v-if="dataSelected.existingBankDebt == 9">
							<label class="fw-medium mb-1" for="comp4">Other Institution Name</label>
							<b-form-input :readOnly="readOnly" required type="text" placeholder="Other Institution Name"
								v-model="dataSelected.otherInstitutionName"></b-form-input>
						</div>
						<div class="col-4" v-if="dataSelected.existingBankDebt != 0">
							<label class="fw-medium mb-1" for="comp4">Existing Debt Amount</label>
							<b-form-input :readOnly="readOnly" required type="number" placeholder="Id Here"
								v-model="dataSelected.existingDebtAmount"></b-form-input>
						</div>
					</div> -->
				</b-card-body>
				<!-- End Section -->


				<!-- Co Applicant Section -->

				<b-card-body class="bg-light mb-5">
					<div class="d-flex justify-content-between border-bottom  mb-4  pb-2">
						<h4 class="card-title mb-1 py-2">Co Applicant Info</h4>
						<b-button @click="goToCoApplicantDetail" variant="primary">View Details
						</b-button>
					</div>
					<div class="row mb-4">
						<div class="col-4 mb-2">
							<label class="h6">Search a customer</label>
							<Select :items="optionsCoApp" @option-selected="handleOptionCoApplicant"
								@search-input="handleSearchCoApplicant" />

						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant Identification</label>
							<b-form-input readOnly type="text" placeholder="Identification Here"
								v-model="coApplicantIdentification"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant FirstName</label>
							<b-form-input readOnly type="text" placeholder="FirstName Here"
								v-model="coApplicantFirstName"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant LastName</label>
							<b-form-input readOnly type="text" placeholder="LastName Here"
								v-model="coApplicantLastName"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Co Applicant Phone</label>
							<b-form-input readOnly type="text" placeholder="Phone Here"
								v-model="coApplicantPhone1"></b-form-input>
						</div>

					</div>

				</b-card-body>
				<!-- End Section -->

				<!-- Guarantor Section -->

				<b-card-body class="bg-light mb-5">

					<div class="d-flex justify-content-between border-bottom  mb-4  pb-2">
						<h4 class="card-title mb-1 py-2">Guarantor Info</h4>
						<b-button @click="goToGuarantorDetail" variant="primary">View Details
						</b-button>
					</div>
					<div class="row mb-4">
						<div class="col-4 mb-2">
							<label class="h6">Search a customer</label>
							<Select :items="optionsGuarantor" @option-selected="handleOptionGuarantor"
								@search-input="handleSearchGuarantor" />

						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Guarantor Identification</label>
							<b-form-input readOnly type="text" placeholder="Identification Here"
								v-model="guarantorIdentification"></b-form-input>
						</div>
						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Guarantor FirstName</label>
							<b-form-input readOnly type="text" placeholder="FirstName Here"
								v-model="guarantorFirstName"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Guarantor LastName</label>
							<b-form-input readOnly type="text" placeholder="LastName Here"
								v-model="guarantorLastName"></b-form-input>
						</div>

						<div class="col-4">
							<label class="fw-medium mb-1" for="fname4">Guarantor Phone</label>
							<b-form-input readOnly type="text" placeholder="Phone Here"
								v-model="guarantorPhone1"></b-form-input>
						</div>
					</div>
				</b-card-body>
				<!-- End Section -->

				<!-- Actions Section -->
				<div class="p-35" v-if="!readOnly && hasPermission">
					<div class="btn-grp text-right">
						<b-button pill :disabled="loadingSave" variant="primary" class="px-4" type="submit">
							<b-spinner small v-if="loadingSave"></b-spinner>
							<span v-else>Save</span>
						</b-button>
					</div>
				</div>
				<!-- End Section -->
			</b-form>
		</b-card>
	</b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "../customers/Header.vue";
import Select from '../Select.vue'
import { debounce } from 'lodash';
export default {
	name: "Customer",
	components: {
		Header,
		Select
	},
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		optionsCountry: [
			{ value: null, text: "Please select an option" },
			{ value: "Aruba", text: "Aruba" },
			{ value: "US", text: "Estados Unidos" },
		],
		optionsTitle: [
			{ value: null, text: "Please select an option" },
			{ value: "mrs", text: "Mrs" },
		],
		countrySelected: null,
		titleSelected: null,
		dataSelected: {
			identificationTypeId1: 2,
			identificationType1: "",
			identificationTypeId2: 3,
			identificationType2: "",
			placeOfBirthId: null,
			nationalityId: null,
			birthDate: null,
			gender: null,
			maritalStatusId: null,
			spousesFullName: null,
			bankId: null,
			bankAccountNumber: "",
			existingBankDebt: 0,
			existingDebtAmount: null,
			otherInstitutionName: null,
			coApplicantFirstName: null,
			coApplicantLastName: null,
			coApplicantPhone1: null,
			guarantorFirstName: null,
			guarantorLastName: null,
			guarantorPhone1: null,
			brand: null,
			model: null,
			year: null,
			vin: null,
			condition: null,
			mileage: null,
			price: null,
			appraisal: null,
			sellerName: null,
			sellerContact: null,
			sellerAddress: null,
			numberDependentPersons: null
		},
		loadingSave: false,

		maritalStatus: [
			{
				id: 1,
				name: "Casa",
			},
			{
				id: 2,
				name: "Soltero/-a",
			},
			{
				id: 3,
				name: "Biba Hunto",
			},
			{
				id: 4,
				name: "Otro",
			},
		],

		//loaders
		loading: false,
		loadingDocumentTypes: false,

		guarantors: [],
		optionsGuarantor: [],
		debouncedSearchGuarantor: null,
		guarantorFirstName: null,
		guarantorIdentification: null,
		guarantorLastName: null,
		guarantorPhone1: null,
		guarantor: null,

		coApplicant: null,
		coApplicants: [],
		optionsCoApp: [],
		debouncedSearchCoApp: null,
		coApplicantFirstName: null,
		coApplicantIdentification: null,
		coApplicantLastName: null,
		coApplicantPhone1: null,

		queryGuarantor: null,
		purposes: [],
		purposeSelected: null
	}),
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			documentTypes: "documentsTypes/getDocumentsTypes",
			countries: "countries/getCountries",
			cities: "cities/getCities",
			banks: "banks/getBanksDDL",
			personIdentificationTypes: "personIdentificationTypes/getPersonIdentificationTypes",
			authorizations: "auth/getAuthorizations",
			loan: 'applications/getLoan'
		}),
		hasPermission() {
			return this.authorizations.includes('/LoanApplications-Additional-Details')
		}
	},
	methods: {
		async getPurposes() {
			const response = await this.$store.dispatch("applications/get_purposes");
			this.purposes = response?.dataResult ?? []
		},
		async getData() {
			await this.$store.dispatch("personIdentificationTypes/get_person_identification_types");
			await this.$store.dispatch("countries/get_allCountries", { skip: 1, take: 9999 });
			await this.$store.dispatch("cities/get_allCities", { skip: 1, take: 9999 });
			await this.$store.dispatch("banks/get_allBanksDDL");

			// const response = await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
			// this.loan = response?.dataResult

			this.purposeSelected = this.loan.purposeId

			if (this.loan.coApplicantId) {
				this.coApplicant = await this.$store.dispatch("customers/get_customerDetail", this.loan.coApplicantId);
				this.optionsCoApp = [
					{
						text: this.coApplicant.firstName,
						value: this.coApplicant.id
					}
				]
				this.coApplicantIdentification = this.coApplicant.idNumber || "";
				this.coApplicantFirstName = this.coApplicant.firstName || "";
				this.coApplicantLastName = this.coApplicant.lastName || "";
				this.coApplicantPhone1 = this.coApplicant.phone1 || "";
			}
			if (this.loan.guarantorId) {
				this.guarantor = await this.$store.dispatch("customers/get_customerDetail", this.loan.guarantorId);
				this.optionsGuarantor = [
					{
						text: this.guarantor.firstName,
						value: this.guarantor.id
					}
				]
				this.guarantorIdentification = this.guarantor.idNumber || "";
				this.guarantorFirstName = this.guarantor.firstName || "";
				this.guarantorLastName = this.guarantor.lastName || "";
				this.guarantorPhone1 = this.guarantor.phone1 || "";
			}

		},
		async getAdditionalDetails() {
			this.loading = true;
			const id = this.$route.params.customer

			await this.getData();

			if (this.customer.additionalDetails?.id != 0) {
				const response = await this.$store.dispatch("additionalDetails/get_additionalDetailById", id);
				this.dataSelected = {
					...this.dataSelected,
					...response
				}
				this.dataSelected.birthDate = this.dataSelected?.birthDate?.split('T')[0] ?? null
			}

			this.loading = false;
		},
		async saveData() {

			if (this.dataSelected.identificationType1 == 'NOT DEFINED') {
				this.$bvToast.toast("The ID type is required", {
					title: "Error",
					variant: "danger",
					solid: true,
				});
				return
			}
			this.dataSelected.customerId = this.$route.params.customer;
			this.loadingSave = true;
			if (this.customer.additionalDetails.id == 0) {
				await this.$store.dispatch("additionalDetails/add_additionalDetail", { loanApplicationId: this.$route.query.loanApplicationId, data: this.dataSelected });
			} else {
				await this.$store.dispatch("additionalDetails/update_additionalDetail", { id: this.$route.query.loanApplicationId, data: this.dataSelected });
			}

			if (this.coApplicant) {
				await this.$store.dispatch(`applications/update_coApplicant`, {
					id: this.$route.query.loanApplicationId,
					coApplicantId: this.coApplicant?.id
				});
			}

			if (this.guarantor) {
				await this.$store.dispatch(`applications/update_guarantor`, {
					id: this.$route.query.loanApplicationId,
					guarantorId: this.guarantor.id
				});
			}


			await this.$store.dispatch(`applications/update_vehicleDetail`, {
				id: this.$route.query.loanApplicationId,
				...this.dataSelected
			});


			await this.updatePurpose()


			await this.$store.dispatch("productRequest/get_processResquest", this.$route.query.loanApplicationId);



			this.loadingSave = false;
		},
		handleSearchCoApplicant(query) {
			this.query = query
			this.debouncedSearchCoApp()
		},
		handleOptionCoApplicant(item) {
			this.coApplicant = this.coApplicants.find(el => el.id == item.value)
			if (this.coApplicant) {
				const customer = this.coApplicant;
				this.coApplicantIdentification = customer.idNumber || "";
				this.coApplicantFirstName = customer.firstName || "";
				this.coApplicantLastName = customer.lastName || "";
				this.coApplicantPhone1 = customer.phone1 || "";
			}
		},
		async getCoApplicant() {
			const response = await this.$store.dispatch("customers/get_allCustomers", { skip: 1, take: 20, status: null, query: this.query });
			this.coApplicants = response?.dataResult ?? []
			this.optionsCoApp = this.coApplicants.map(el => {
				return {
					text: el.firstName,
					value: el.id
				}
			})
		},

		async getGuarantor() {
			const response = await this.$store.dispatch("customers/get_allCustomers", { skip: 1, take: 20, status: null, query: this.queryGuarantor });
			this.guarantors = response?.dataResult ?? []
			this.optionsGuarantor = this.guarantors.map(el => {
				return {
					text: el.firstName,
					value: el.id
				}
			})
		},

		async updatePurpose() {
			await this.$store.dispatch("applications/update_purpose", {
				id: this.$route.query.loanApplicationId,
				purposeId: this.purposeSelected,
			});
		},

		handleSearchGuarantor(query) {
			this.queryGuarantor = query
			this.debouncedSearchGuarantor()
		},
		handleOptionGuarantor(item) {
			this.guarantor = this.guarantors.find(el => el.id == item.value)
			if (this.guarantor) {
				const customer = this.guarantor;
				this.guarantorIdentification = customer.idNumber || "";
				this.guarantorFirstName = customer.firstName || "";
				this.guarantorLastName = customer.lastName || "";
				this.guarantorPhone1 = customer.phone1 || "";
			}
		},
		setVehicleInfo() {
			if (JSON.parse(this.loan.applicationDetails)) {
				const payload = JSON.parse(this.loan.applicationDetails)
				this.dataSelected.brand = payload.Brand
				this.dataSelected.model = payload.Model
				this.dataSelected.year = payload.Year
				this.dataSelected.vin = payload.VIN
				this.dataSelected.condition = payload.Condition
				this.dataSelected.color = payload.Color
				this.dataSelected.mileage = payload.Mileage
				this.dataSelected.price = payload.Price
				this.dataSelected.appraisal = payload.Appraisal
				this.dataSelected.sellerName = payload.SellerName
				this.dataSelected.sellerContact = payload.SellerContact
				this.dataSelected.sellerAddress = payload.SellerAddress
			}
		},
		goToCoApplicantDetail() {
			this.$router.push(`/customers/detail/${this.coApplicant.id}`)
		},
		goToGuarantorDetail() {
			this.$router.push(`/customers/detail/${this.guarantor.id}`)
		}
	},
	created() {
		if (this.new) {
			this.getData();
		} else {
			this.getAdditionalDetails();
		}

		this.getPurposes()

		this.setVehicleInfo()
		this.debouncedSearchCoApp = debounce(this.getCoApplicant, 300);
		this.debouncedSearchGuarantor = debounce(this.getGuarantor, 300)
	},
};
</script>
