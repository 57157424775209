<template>
	<div>
		<b-card no-body class="mb-4">
			<template v-if="!readOnly">
				<Header />
			</template>

			<b-row>
				<b-col lg="6" cols="12">
					<h2 class="mb-3">Incomes</h2>

					<div class="row mb-3">
						<div class="col-4">
							<b-form-group label-for="incomeType" :state="incomeTypeState"
								invalid-feedback="This field is required">
								<b-form-select v-model="incomeType" id="incomeType" :state="incomeTypeState"
									@blur="validateIncomeType">
									<option value="" disabled>Select a type</option>
									<option :value="income" v-for="income in incomesTypes" :key="income.id">{{
										income.type }}
									</option>
								</b-form-select>
							</b-form-group>
						</div>
						<div class="col-4">
							<b-form-group label-for="amount" class="px-2" :state="amountState"
								invalid-feedback="This field is required and must be greater than 0">
								<b-form-input v-model="amount" id="amount" type="number" :state="amountState"
									@blur="validateAmount" placeholder="Enter amount"></b-form-input>
							</b-form-group>
						</div>
						<div class="col-3">
							<b-form-group>
								<b-button variant="primary" @click="addIncome">Add</b-button>
							</b-form-group>
						</div>
					</div>

					<!-- Listado de ingresos -->
					<b-table striped hover small :items="incomes" header class="mt-3" :fields="fields">
						<template #cell(type)="data">
							<b>{{ data.item.type }}</b>
						</template>
						<template #cell(amount)="data">
							AWG {{ data.item.amount }}
						</template>

						<template #cell(action)="data">
							<b-button variant="outline-danger" size="sm" class="d-flex align-items-center ml-2"
								@click="deleteIncome(data)">
								<feather type="trash" class="feather-sm"></feather>
							</b-button>
						</template>
					</b-table>
				</b-col>

				<b-col lg="6" cols="12">
					<h2 class="mb-3">Expenses</h2>

					<div class="row mb-3">
						<div class="col-4">
							<b-form-group label-for="expenseType" :state="expenseTypeState"
								invalid-feedback="This field is required" class="flex-1">
								<b-form-select v-model="expenseType" id="expenseType" :state="expenseTypeState"
									@blur="validateExpenseType">
									<option value="" disabled>Select a type</option>
									<option v-for="expense in expensesTypes" :key="expense.id" :value="expense">{{
										expense.type }}
									</option>
								</b-form-select>
							</b-form-group>

						</div>
						<div class="col-4">

							<b-form-group label-for="expenseAmount" class="px-2" :state="expenseAmountState"
								invalid-feedback="This field must be greater than 0">
								<b-form-input v-model="expenseAmount" id="expenseAmount" type="number"
									:state="expenseAmountState" @blur="validateExpenseAmount"
									placeholder="Enter amount"></b-form-input>
							</b-form-group>
						</div>
						<div class="col-3">
							<b-form-group>
								<b-button variant="danger" @click="addExpense">Add</b-button>
							</b-form-group>
						</div>
					</div>

					<!-- Listado de gastos -->
					<b-table striped hover small :items="expenses" class="mt-3" :fields="fields">
						<template #cell(type)="data">
							<b>{{ data.item.type }}</b>
						</template>
						<template #cell(amount)="data">
							AWG {{ data.item.amount }}
						</template>
						<template #cell(action)="data">
							<b-button variant="outline-danger" size="sm" class="d-flex align-items-center ml-2"
								@click="deleteExpenses(data)">
								<feather type="trash" class="feather-sm"></feather>
							</b-button>
						</template>
					</b-table>

				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import Header from "@/components/customers/Header.vue";
import { mapGetters } from "vuex";

export default {
	name: "Finance",
	components: {
		Header,
	},
	props: {
		readOnly: Boolean,
	},
	data() {
		return {
			// Incomes
			incomeType: "",
			amount: null,
			incomes: [],
			incomeTypeState: null,
			amountState: null,

			// Expenses
			expenseType: "",
			expenseAmount: null,
			expenses: [],
			expenseTypeState: null,
			expenseAmountState: null,
			incomesTypes: [
				{ id: 0, type: 'Other Income' },
				{ id: 1, type: 'Other Income Co-Applicant' },
				{ id: 2, type: 'Pension' },
				{ id: 3, type: 'Pension Co-Applicant' },
			],
			expensesTypes: [
				{ id: 0, type: 'Rent' },
				{ id: 1, type: 'Utilities' },
				{ id: 2, type: 'Groceries' },
				{ id: 3, type: 'Other' },
			],
			fields: [
				{ key: "id", label: "ID" },
				{ key: "type", label: "Type" },
				{ key: "amount", label: "amount" },
				{ key: "action", label: "Action" },
			]
		};
	},
	computed: {
		...mapGetters({
			loan: 'applications/getLoan'
		}),
	},
	methods: {
		// Validations for incomes
		validateIncomeType() {
			this.incomeTypeState = this.incomeType !== "";
		},
		validateAmount() {
			this.amountState = this.amount > 0;
		},
		addIncome() {
			this.validateIncomeType();
			this.validateAmount();

			if (this.incomeTypeState && this.amountState) {
				this.incomes.push({
					...this.incomeType,
					amount: this.amount,
				});

				this.saveIncomes()

				// Limpiar los inputs
				this.incomeType = "";
				this.amount = null;
				this.incomeTypeState = null;
				this.amountState = null;
			}
		},

		// Validations for expenses
		validateExpenseType() {
			this.expenseTypeState = this.expenseType !== "";
		},
		validateExpenseAmount() {
			this.expenseAmountState = this.expenseAmount > 0;
		},
		addExpense() {
			this.validateExpenseType();
			this.validateExpenseAmount();

			if (this.expenseTypeState && this.expenseAmountState) {
				this.expenses.push({
					...this.expenseType,
					amount: this.expenseAmount,
				});

				this.saveExpenses()


				this.expenseType = "";
				this.expenseAmount = null;
				this.expenseTypeState = null;
				this.expenseAmountState = null;
			}
		},

		async saveIncomes() {
			await this.$store.dispatch(`applications/update_incomes`, {
				id: this.$route.query.loanApplicationId,
				body: this.incomes
			});
			await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
		},

		async saveExpenses() {
			await this.$store.dispatch(`applications/update_expenses`, {
				id: this.$route.query.loanApplicationId,
				body: this.expenses
			});
			await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
		},

		deleteIncome(data) {
			this.incomes = this.incomes.filter(income => income.id !== data.item.id);
			this.saveIncomes();
		},

		// Eliminar un expense
		deleteExpenses(data) {
			this.expenses = this.expenses.filter(expense => expense.id !== data.item.id);
			this.saveExpenses();
		}

	},
	watch: {
		loan(loanApp) {
			if (loanApp) {
				if (JSON.parse(loanApp.incomes)) {
					this.incomes = JSON.parse(loanApp.incomes).map(income => {
						return Object.keys(income).reduce((acc, key) => {
							acc[key.toLowerCase()] = income[key];
							return acc;
						}, {});
					});

				}
				if (JSON.parse(loanApp.expenses)) {
					this.expenses = JSON.parse(loanApp.expenses).map(expense => {
						return Object.keys(expense).reduce((acc, key) => {
							acc[key.toLowerCase()] = expense[key];
							return acc;
						}, {});
					});
				}

			} else {
				this.incomes = [];
				this.expenses = [];
			}
		}
	}

};
</script>