<template>
	<div>
		<b-overlay :v-show="loading" rounded="sm" />
		<b-row v-if="!loading">
			<b-col cols="12">
				<b-card no-body class="mb-4">
					<b-card-body class="p-3">
						<b-tabs content-class="mt-3" align="left" v-model="tabIndex">
							<b-tab title="Main" active>
								<Main />
							</b-tab>

							<b-tab title="Additional Details">
								<AditionalDetails />
							</b-tab>

							<b-tab title="Documents">
								<ScannedDocuments />
							</b-tab>

							<b-tab title="Comments">
								<Comments />
							</b-tab>

							<b-tab title="Compliance" @click="hadleCompliance">
								<Membercheck />
							</b-tab>

							<b-tab title="Finance">
								<Finance />
							</b-tab>

							<b-tab title="Screening">
								<Screening />
							</b-tab>

							<b-tab title="Job Verification">
								<Employment />
							</b-tab>

							<b-tab title="Verify Info">
								<VerifyInformation v-if="tabIndex == 8" />
							</b-tab>

							<b-tab title="Approval">
								<Approval />
							</b-tab>

							<!-- <b-tab title="Create Loan">
							<CreateLoan />
						</b-tab>

						<b-tab title="Sign">
							<Signed />
						</b-tab>

						<b-tab title="Transferred Funds">
							<TransferredFunds />
						</b-tab> -->
						</b-tabs>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</div>

</template>

<script>
import Main from "@/components/applications/Main.vue";
import AditionalDetails from "@/components/applications/AditionalDetails.vue";
import ScannedDocuments from "@/components/applications/ScannedDocuments.vue";
import Comments from "@/components/customers/Comments.vue";
import Employment from "@/components/applications/Employment.vue";
import Membercheck from "@/components/applications/MemberCheck.vue";
import Screening from "../../components/applications/Screening.vue";
import VerifyInformation from "../../components/applications/VerifyInformation.vue";
import Approval from "../../components/applications/Approval.vue";
import Finance from "../../components/applications/Finance.vue";
// import Signed from "../../components/customers/Signed.vue";
// import TransferredFunds from "../../components/customers/TransferredFunds.vue";
// import CreateLoan from "../../components/customers/CreateLoan.vue";
export default {
	name: "Customer",
	components: {
		Main,
		AditionalDetails,
		ScannedDocuments,
		Comments,
		Employment,
		Membercheck,
		Screening,
		VerifyInformation,
		Approval,
		Finance,
		// Signed,
		// TransferredFunds,
		// CreateLoan
	},
	data: () => ({
		loading: false,
		tabIndex: 0,
	}),
	methods: {
		async getData() {
			this.loading = true;
			await this.$store.dispatch("customers/get_customer", this.$route.params.customer);
			await this.$store.dispatch("applications/get_loanById", this.$route.query.loanApplicationId);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.query.loanApplicationId);
			this.loading = false;
		},
		hadleCompliance() {

		}
	},
	created() {
		this.getData();
	},
};
</script>
