<template>
    <div>
        <b-button variant="primary" v-b-modal.modal-preview-report>View Report</b-button>

        <b-modal id="modal-preview-report" title="Upload Files" hide-footer centered>
            <b-container fluid>
                <div style="max-height: 60vh; overflow-y: auto;" :class="loading ? 'opacity' : ''">
                    <div class="row" id="container" style="width: 500px;">
                        <div class="col-12 mb-4 page" v-for="item in scans" :key="item.id">
                            <article class="bg-white p-2 h-100 rounded-lg">
                                <div class="d-flex justify-content-between align-items-center border-bottom pb-1">
                                    <h5 class="mb-0" style="font-size: 12px;">UID #{{ item.uniqueId }}</h5>
                                    <span class="bg-light p-1 text-dark rounded-lg">Match Rate | <strong>{{
                                        item.matchRate
                                            }}</strong></span>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-6">
                                                <!-- fisrtname, id, lastname, primary location, Deceased, Gender, Categories detail, Match Rate, Date of Birth -->
                                                <div class="py-1 d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <h6 class="mb-1">Name</h6>
                                                        <p class="mb-0">{{ item.primaryFirstName }} {{
                                                            item.primaryLastName
                                                        }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="py-1 d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <h6 class="mb-1">Primary Location</h6>
                                                        <p class="mb-0">{{ item.primaryLocation }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="py-1 d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <h6 class="mb-1">Deceased</h6>
                                                        <p class="mb-0">{{ item.deceased }}</p>
                                                    </div>
                                                </div>
                                                <div class="py-1 d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <h6 class="mb-1">Gender</h6>
                                                        <p class="mb-0">{{ item.gender }}</p>
                                                    </div>
                                                </div>
                                                <div class="py-1 d-flex justify-content-start align-items-center">
                                                    <div>
                                                        <h6 class="mb-1">Date of Birth</h6>
                                                        <p class="mb-0">{{ item.dateOfBirth }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="p-1 bg-light rounded-lg my-2">
                                            <h6 class="mb-1">Categories Details</h6>
                                            <p class="mb-0">{{ item.categories }}</p>
                                        </div>

                                        <div class="p-1 bg-light rounded-lg my-2">
                                            <h6 class="mb-1">Further Information</h6>
                                            <p class="mb-0">{{ item.furtherInformation }}</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" :disabled="loading" @click="generatePDF">
                        <b-spinner small v-if="loading"></b-spinner>
                        Upload Report
                    </b-button>
                </div>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import jsPDF from 'jspdf';
import { mapGetters } from "vuex";

export default {
    name: 'MemberCheckReport',
    data() {
        return {
            loading: false
        };
    },
    props: {
        scans: Array
    },
    computed: {
        ...mapGetters({
            customer: "customers/getCustomer",
        }),
    },
    methods: {
        async getData() {
            await this.$store.dispatch("documents/get_documents", { skip: 1, take: 10, customerId: this.$route.params.customer });
        },
        async generatePDF() {
            this.loading = true
            const content = document.getElementById('container');
            content.style.maxWidth = '500px';

            const doc = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: 'a4',
            });

            doc.html(content, {
                callback: async (pdf) => {
                    // Convert the PDF to a Blob
                    const pdfBlob = pdf.output('blob');

                    // Define the filename
                    const fileName = `Membercheck-report-${this.customer.firstName}-${this.customer.lastName}.pdf`;

                    // Prepare the form data
                    const formData = new FormData();
                    formData.append("files", pdfBlob, fileName);

                    let data = {
                        customerId: this.customer.additionalDetails.customerId,
                        loanApplicationId: this.$route.params.customer,
                        documentTypeId: '1',
                        formData,
                    };

                    try {
                        const res = await this.$store.dispatch("documents/add_documents", data);
                        await this.getData()
                        if (res) {
                            this.$bvToast.toast("Report uploaded successfully", {
                                title: "Success",
                                variant: "success",
                                solid: true,
                            });

                            this.$bvModal.hide("modal-preview-report");
                        } else {
                            this.$bvToast.toast("Error uploading report", {
                                title: "Error",
                                variant: "danger",
                                solid: true,
                            });
                        }
                    } catch (error) {
                        this.$bvToast.toast("Error uploading report", {
                            title: "Error",
                            variant: "danger",
                            solid: true,
                        });
                    } finally {
                        this.loading = false;
                        content.style.maxWidth = '';
                    }
                },
                x: 10,
                y: 10,
                html2canvas: {
                    scale: 0.85, // Adjust scale factor if content is too large
                },
            });
        },
    }
};
</script>

<style scoped>
#container {
    font-size: 8px;
}

#container h6 {
    font-size: 10px;
}

.page {
    page-break-after: always;
}

.opacity {
    opacity: .25;
}
</style>
