<template>
	<b-card no-body>
		<template v-if="!readOnly">
			<Header />
			<hr class="m-0" />
		</template>
		<MemberDetail />
		<MemberCoApplicant v-if="loan.coApplicantId" />
		<MemberGuarantor v-if="loan.guarantorId" />
	</b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/customers/Header.vue";
import MemberDetail from "./MemberDetail.vue";
import MemberCoApplicant from "./MemberCoApplicant.vue";
import MemberGuarantor from "./MemberGuarantor.vue";

export default {
	components: {
		Header,
		MemberDetail,
		MemberGuarantor,
		MemberCoApplicant
	},
	name: "MemberCheck",
	props: {
		new: Boolean,
		readOnly: Boolean,
	},
	data: () => ({
		firstName: "",
		lastName: "",
		loading: false,
		scans: [],
		selectedMatch: null,
		loadingPep: false,
		maritalStatusArray: [
			{
				id: 1,
				name: "Casa",
			},
			{
				id: 2,
				name: "Soltero/-a",
			},
			{
				id: 3,
				name: "Biba Hunto",
			},
			{
				id: 4,
				name: "Otro",
			},
		],
		pep: false,
		pepConfirm: null,
		typeRisk: null,
		decision: '',
		idType: null,
		identification: '',
		expDate: ''

	}),
	computed: {
		...mapGetters({
			customer: "customers/getCustomer",
			authorizations: "auth/getAuthorizations",
			loan: 'applications/getLoan'
		}),
		maritalStatus() {
			return this.maritalStatusArray.find(el => el.id == this.customer?.additionalDetails?.maritalStatusId)
		},
		hasPermission() {
			return this.authorizations.includes('/LoanApplications-Membercheck')
		},
		formattedDate() {
			if (this.customer.scansLastVerificationDate) {
				const dateArr = this.customer.scansLastVerificationDate.split('T')
				return dateArr[0] + ' ' + dateArr[1]
			}
			return '--'
		}
	},
	methods: {
		async validateMember() {
			this.loading = true;
			await this.$store.dispatch("scans/memberVerification", {
				firstName: this.firstName,
				lastName: this.lastName,
				customerId: this.customer.id,
				loanApplicationId: this.$route.query.loanApplicationId,
			});
			await this.$store.dispatch("customers/get_customer", this.$route.params.customer);
			await this.getData();
			this.firstName = "";
			this.lastName = "";
			this.loading = false;
		},
		async getData() {
			this.loading = true;
			this.scans = await this.$store.dispatch("scans/get_scanById", this.$route.params.customer);
			await this.$store.dispatch("productRequest/get_processResquest", this.$route.query.loanApplicationId);

			this.firstName = this.customer.firstName;
			this.lastName = this.customer.lastName;
			this.loading = false;
		},

		setData() {
			this.typeRisk = this.customer.riskTypeId
			this.pep = this.customer.pep
			this.pepConfirm = this.customer.peP_Confirmed
			if (this.customer.peP_Form) {
				this.idType = this.customer.peP_Form.identificationTypeId
				this.identification = this.customer.peP_Form.idNumber
				this.expDate = this.customer.peP_Form.expirationDate
				this.decision = this.customer.peP_Form.decisionSummary
			}
		},

		async updatePepData() {
			const documents = [{ text: 'Passport', value: 'passport' }, { text: 'ID', value: 'id' }]
			const idName = documents.find(el => el.value == this.idType)
			let pepForm = null

			if (this.pepConfirm) {
				pepForm = {
					identificationTypeId: this.idType,
					identificationTypeName: idName.text,
					idNumber: this.identification,
					expirationDate: this.expDate,
					decisionSummary: this.decision,
				}
			}


			let payload = {
				customerId: this.customer.id,
				riskTypeId: this.typeRisk,
				pep: this.pep,
				peP_Confirmed: this.pepConfirm,
				pepForm,
			}

			await this.$store.dispatch("customers/update_pepInformation", payload);
		},
	},
	watch: {
		customer: function () {
			this.setData()
		},
		pep: function (value) {
			if (!value) {
				this.typeRisk = 1
				return
			}
			if (value && this.pepConfirm) {
				this.typeRisk = 3
			}
			if (value && !this.pepConfirm) {
				this.typeRisk = 2
			}
		},
		pepConfirm: function (value) {
			if (value && this.pep) {
				this.typeRisk = 3
			}
			if (!value && this.pep) {
				this.typeRisk = 2
			}
			if (!value && !this.pep) {
				this.typeRisk = 1
			}
		},
	},
	created() {
		if (!this.new) {
			this.getData();
		}
	},
};
</script>
